import { mapState } from "vuex";
import "vue-material-design-icons/styles.css";
import Chart from "chart.js";
import store from "@/store/index";
import {
  statisticsBreachesGet,
  statisticsBreachesTotalGet,
  statisticsBreachesStatsIdGet,
} from "@/clients/tracelight";
import wordcloud from "@/components/WordCloud.vue";
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  name: "BreachDashboard",
  data: () => ({
    fab: false,
    snackbar: false,
    barData: [],
    barLabels: [],
    barChart: null,
    stopLoading: false,
    pageCall: 0,
    showClipboard: false,
    clipboardMessage: null,
    renderComponent: true,
    selectedSort: "Last updated",
    searchOn: true,
    toggle_exclusive: [],
    itemsPerPageArray: [9, 18, 36, -1],
    searchTerm: "",
    sortDesc: false,
    pageStart: true,
    page: 1,
    itemsPerPage: -1,
    scrollTrue: true,
    items: [],
    myColors: ["#fff"],
    currentPage: 1,
    observer: null,
    currentSortValue: "modified_at",
    btnLength0: null,
    btnLength1: null,
    btnLength2: null,
    btnLength3: null,
    btnLength4: null,
    btnLength5: null,
    initialLoad: true,
  }),
  components: {
    wordcloud,
  },
  computed: {
    breachStats() {
      return store.state.breachDashboard.breachStats;
    },
    breachStatsTotal() {
      return store.state.breachDashboard.breachStatsTotal;
    },
    totalBreaches() {
      let array = [];
      if (store.state.breachDashboard.breachStatsTotal.length) {
        array.push(
          store.state.breachDashboard.breachStatsTotal[0].total_breaches
        );
      }
      return array[0];
    },
    totalBreachRecords() {
      let array = [];
      if (store.state.breachDashboard.breachStatsTotal.length) {
        array.push(
          store.state.breachDashboard.breachStatsTotal[0].total_records
        );
      }
      return array[0];
    },
    totalBreachEmail() {
      let array = [];
      if (store.state.breachDashboard.breachStatsTotal.length) {
        array.push(
          store.state.breachDashboard.breachStatsTotal[0].rollup_fields.email
            .total
        );
      }
      return array[0];
    },
    totalBreachIp() {
      let array = [];
      if (store.state.breachDashboard.breachStatsTotal.length) {
        array.push(
          store.state.breachDashboard.breachStatsTotal[0].rollup_fields.ip.total
        );
      }
      return array[0];
    },
    totalBreachPassword() {
      let array = [];
      if (store.state.breachDashboard.breachStatsTotal.length) {
        array.push(
          store.state.breachDashboard.breachStatsTotal[0].rollup_fields.password
            .total
        );
      }
      return array[0];
    },
    totalBreachAddress() {
      let array = [];
      if (store.state.breachDashboard.breachStatsTotal.length) {
        array.push(
          store.state.breachDashboard.breachStatsTotal[0].rollup_fields.address
            .total
        );
      }
      return array[0];
    },
    totalBreachOrganization() {
      let array = [];
      if (store.state.breachDashboard.breachStatsTotal.length) {
        array.push(
          store.state.breachDashboard.breachStatsTotal[0].rollup_fields
            .organization.total
        );
      }
      console.warn(array);
      return array[0];
    },
    totalBreachPII() {
      let array = [];
      if (store.state.breachDashboard.breachStatsTotal.length) {
        array.push(
          store.state.breachDashboard.breachStatsTotal[0].rollup_fields
            .personal_information.total
        );
      }
      return array[0];
    },
    mostCommonPasswords() {
      let array = [];
      if (store.state.breachDashboard.breachStatsTotal.length) {
        for (let [key, value] of Object.entries(
          store.state.breachDashboard.breachStatsTotal[0].fields.password
            .most_common
        )) {
          array.push({ PW: key, Count: value });
        }
      }
      console.debug(array);
      return array;
    },
    numberOfPages() {
      return Math.ceil(this.breachStats.length / this.itemsPerPage);
    },
    ...mapState(["confirmedUser"]),
  },
  beforeCreate() {
    this.currentSortValue = "modified_at";
    store.dispatch("breachDashboard/updateBreachStats", {
      page: 1,
      latest: true,
      currentSortValue: this.currentSortValue,
    });
    let params;
    statisticsBreachesTotalGet(params, {})
      .then(function (result) {
        console.debug(result);
        store.commit("breachDashboard/fillBreachStatsTotal", result.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  },
  mounted() {
    let th = this;
    let styles = {
      "overflow-x": "hidden",
    };
    let obj = document.getElementsByTagName("html");
    Object.assign(obj[0].style, styles);
    window.addEventListener("scroll", th.onScrollEvent);

    setTimeout(() => {
      AOS.init({
        offset: 0,
        duration: 600,
        easing: "ease-in-sine",
        delay: 100,
      });
      var el1 = document.querySelector(
        "i.v-icon.notranslate.v-icon--link.material-icons.theme--dark"
      );
      var el2 = document.querySelector(".v-icon.v-icon--link");
      if (el1 !== null) {
        el1.style.color = "#e86051";
      }
      if (el2 !== null) {
        el2.style.color = "#e86051";
      }
    }, 1000);
    setTimeout(() => {
      th.observer = new IntersectionObserver(th.callback, {
        root: null,
        rootMargin: "0px 0px 0px 1000px",
        threshold: 1,
      });
      th.observer.observe(th.$refs.divAsTarget);
      th.pageStart = false;
      th.btnLength0 = document
        .getElementById("zeroSelect")
        .getBoundingClientRect().width;
      th.btnLength1 = document
        .getElementById("oneSelect")
        .getBoundingClientRect().width;
      th.btnLength2 = document
        .getElementById("twoSelect")
        .getBoundingClientRect().width;
      th.btnLength3 = document
        .getElementById("threeSelect")
        .getBoundingClientRect().width;
      th.btnLength4 = document
        .getElementById("fourSelect")
        .getBoundingClientRect().width;
      th.btnLength5 = document
        .getElementById("fiveSelect")
        .getBoundingClientRect().width;
      console.debug(
        th.btnLength0,
        th.btnLength1,
        th.btnLength2,
        th.btnLength3,
        th.btnLength4,
        th.btnLength5
      );
    }, 2000);
  },
  beforeRouteLeave(to, from, next) {
    if (this.observer != null) {
      this.observer.unobserve(this.$refs.divAsTarget);
    }
    next();
  },
  watch: {
    breachStatsTotal: {
      handler: function (val, oldVal) {
        if (this.initialLoad == true) {
          this.setChart();
          this.initialLoad = false;
          console.debug(val, oldVal);
        }
      },
    },
    scrollTrue: {
      handler: function (val, oldVal) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        console.debug(val, oldVal);
      },
    },
    searchTerm: {
      handler: function (v, ov) {
        let th = this;
        if (th.pageStart == false) {
          th.stopLoading = false;
          th.currentSortValue = null;
          th.selectedSort = "Last updated";
          let toggle_btns = document.getElementById("toggle_btns");
          if (v == null || v == "" || v == undefined) {
            toggle_btns.classList.remove("is_active_toggleBtns");
            th.currentPage = 1;
            th.stopLoading = false;
            th.currentSortValue = "modified_at";
            store.dispatch("breachDashboard/updateBreachStats", {
              page: th.currentPage,
              latest: true,
              currentSortValue: th.currentSortValue,
            });
          }
          if (v !== null) {
            if (v.length > 0) {
              if (toggle_btns.classList.contains("is_active_toggleBtns")) {
                toggle_btns.classList.remove("is_active_toggleBtns");
                toggle_btns.classList.add("is_active_toggleBtns");
              } else {
                toggle_btns.classList.add("is_active_toggleBtns");
              }
            }
          }
        }
      },
    },
    toggle_exclusive: {
      handler: function (val, oldVal) {
        let th = this;
        th.zeroShow = false;
        th.oneShow = false;
        th.twoShow = false;
        th.threeShow = false;
        th.fourShow = false;
        th.fiveShow = false;
        console.debug(val);
        if (th.pageStart == false) {
          th.currentSortValue = null;
          th.stopLoading = false;
          th.currentPage = 1;
          if (val == undefined || val == "undefined") {
            th.selectedSort = "Last Updated";
            th.currentSortValue = "modified_at";
            th.searchTerm = "";
            store.dispatch("breachDashboard/updateBreachStats", {
              page: th.currentPage,
              latest: true,
              currentSortValue: th.currentSortValue,
            });
            th.searchOn = true;
          } else {
            th.searchOn = false;
          }
          th.resetCardStyle();
          if (val !== null) {
            th.selectedSort = "";
            if (val == 0) {
              th.currentSortValue = "organization";
              th.zeroShow = true;
              store.dispatch("breachDashboard/updateBreachStats", {
                page: th.currentPage,
                currentSortValue: th.currentSortValue,
              });

              let cardFlexClass = document.getElementsByClassName("class_0");
              th.updateCards(cardFlexClass);
            }
            if (val == 1) {
              th.oneShow = true;
              th.currentSortValue = "email";
              store.dispatch("breachDashboard/updateBreachStats", {
                page: th.currentPage,
                currentSortValue: th.currentSortValue,
              });
              let cardFlexClass = document.getElementsByClassName("class_1");
              th.updateCards(cardFlexClass);
            }
            if (val == 2) {
              th.twoShow = true;
              th.currentSortValue = "personal_information";

              store.dispatch("breachDashboard/updateBreachStats", {
                page: th.currentPage,
                currentSortValue: th.currentSortValue,
              });
              let cardFlexClass = document.getElementsByClassName("class_2");
              th.updateCards(cardFlexClass);
            }
            if (val == 3) {
              th.currentSortValue = "ip";

              th.threeShow = true;
              store.dispatch("breachDashboard/updateBreachStats", {
                page: th.currentPage,
                currentSortValue: th.currentSortValue,
              });
              let cardFlexClass = document.getElementsByClassName("class_3");
              th.updateCards(cardFlexClass);
            }
            if (val == 4) {
              th.currentSortValue = "password";
              th.fourShow = true;
              store.dispatch("breachDashboard/updateBreachStats", {
                page: th.currentPage,
                currentSortValue: th.currentSortValue,
              });
              let cardFlexClass = document.getElementsByClassName("class_4");
              th.updateCards(cardFlexClass);
            }
            if (val == 5) {
              th.currentSortValue = "address";

              th.fiveShow = true;
              store.dispatch("breachDashboard/updateBreachStats", {
                page: th.currentPage,
                currentSortValue: th.currentSortValue,
              });
              let cardFlexClass = document.getElementsByClassName("class_5");
              th.updateCards(cardFlexClass);
            }
          }
        }
      },

      immediate: true,
    },
    selectedSort: {
      handler: function (val) {
        let th = this;
        if (th.pageStart == false) {
          console.debug(val);
          th.currentPage = 1;
          if (val == "Last updated") {
            th.resetCardStyle();
            th.currentSortValue = "modified_at";
            store.dispatch("breachDashboard/updateBreachStats", {
              page: th.currentPage,
              latest: true,
              currentSortValue: th.currentSortValue,
            });
          } else if (val == "Largest breach") {
            th.resetCardStyle();
            th.currentSortValue = "total_records";
            store.dispatch("breachDashboard/updateBreachStats", {
              page: th.currentPage,
              currentSortValue: th.currentSortValue,
            });
          } else if (val == "Latest release date") {
            th.resetCardStyle();
            th.currentSortValue = "release_date";
            store.dispatch("breachDashboard/updateBreachStats", {
              page: th.currentPage,
              currentSortValue: th.currentSortValue,
            });
          } else {
          }
        }
      },

      immediate: true,
    },
  },
  methods: {
    setChart() {
      let th = this;
      let breachTotalsSum =
        th.totalBreachOrganization +
        th.totalBreachEmail +
        th.totalBreachPII +
        th.totalBreachIp +
        th.totalBreachPassword +
        th.totalBreachAddress;
      let barArray = [
        parseFloat(
          ((th.totalBreachOrganization / breachTotalsSum) * 100).toFixed(2)
        ),
        parseFloat(((th.totalBreachEmail / breachTotalsSum) * 100).toFixed(2)),
        parseFloat(((th.totalBreachPII / breachTotalsSum) * 100).toFixed(2)),
        parseFloat(((th.totalBreachIp / breachTotalsSum) * 100).toFixed(2)),
        parseFloat(
          ((th.totalBreachPassword / breachTotalsSum) * 100).toFixed(2)
        ),
        parseFloat(
          ((th.totalBreachAddress / breachTotalsSum) * 100).toFixed(2)
        ),
      ];
      let arrayLabel = [
        "Organization",
        "Email",
        "PII",
        "IP",
        "Password",
        "Address",
      ];
      let arrayData = barArray;
      let arrayOfObj = arrayLabel.map(function (d, i) {
        return {
          label: d,
          data: arrayData[i] || 0,
        };
      });
      let sortedArrayOfObj = arrayOfObj.sort(function (a, b) {
        return b.data - a.data;
      });
      let newArrayLabel = [];
      let newArrayData = [];
      sortedArrayOfObj.forEach(function (d) {
        newArrayLabel.push(d.label);
        newArrayData.push(d.data);
      });
      console.log(newArrayLabel);
      console.log(newArrayData);
      th.barData = newArrayData;
      th.barLabels = newArrayLabel;
      th.createChart("exChart");
    },
    updateCards(cardFlexClass) {
      let th = this;
      for (let r = 0; r < cardFlexClass.length; r++) {
        let eachCardFlexClass = cardFlexClass[r];
        if (eachCardFlexClass.classList.contains("is_active")) {
          eachCardFlexClass.classList.remove("is_active");
          th.currentSortValue = null;
        } else {
          eachCardFlexClass.classList.add("is_active");
          for (let c = 0; c < th.breachStats.length; c++) {
            let eachItem = th.breachStats[c];
            if (
              eachItem.rollup_fields.address == null ||
              eachItem.rollup_fields.address == undefined
            ) {
              let address = {};
              address.total = 0;
              eachItem.rollup_fields.address = address;
            }
          }
        }
      }
    },
    addMoreUpdateCards(cardFlexClass) {
      let th = this;
      for (let r = 0; r < cardFlexClass.length; r++) {
        let eachCardFlexClass = cardFlexClass[r];
        if (eachCardFlexClass.classList.contains("is_active")) {
        } else {
          eachCardFlexClass.classList.add("is_active");
          for (let c = 0; c < th.breachStats.length; c++) {
            let eachItem = th.breachStats[c];
            if (
              eachItem.rollup_fields.organization == null ||
              eachItem.rollup_fields.organization == undefined
            ) {
              let organization = {};
              organization.total = 0;
              eachItem.rollup_fields.organization = organization;
            }
          }
        }
      }
    },
    resetCardStyle() {
      this.zeroShow = false;
      this.oneShow = false;
      this.twoShow = false;
      this.threeShow = false;
      this.fourShow = false;
      this.fiveShow = false;
      let cardFlex = document.getElementsByClassName("cardFlex");
      for (let w = 0; w < cardFlex.length; w++) {
        let eachCardFlex = cardFlex[w];
        if (eachCardFlex.classList.contains("is_active")) {
          eachCardFlex.classList.remove("is_active");
        }
      }
      let displayBtns = document.getElementsByClassName("displayBtns");
      for (let w = 0; w < displayBtns.length; w++) {
        let displayBtn = displayBtns[w];
        if (displayBtn.classList.contains("v-item--active")) {
          displayBtn.classList.remove("v-item--active");
        }
        if (displayBtn.classList.contains("v-btn--active")) {
          displayBtn.classList.remove("v-btn--active");
        }
      }
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    onScrollEvent() {
      let th = this;
      th.scrollTrue = true;
    },
    searchTermLoad() {
      let th = this;
      if (th.searchTerm.length > 0) {
        th.currentSortValue = null;
        th.currentPage = 1;
        let params = {};
        statisticsBreachesGet(params, {
          results_per_page: 21,
          page: th.currentPage,
          q: th.searchTerm,
          sort_order: "desc",
          sort_by: "modified_at",
        })
          .then(function (result) {
            store.commit("breachDashboard/fillBreachStats", result.data.data);
            console.debug(result);
            if (result.data.data.errorType) {
              alert(result.data.data.errorMessage);
              return;
            } else if (result.data.data.length == 0) {
              store.commit("breachDashboard/fillBreachStats", []);
            } else if (result.data.data.length > 0) {
              for (let r = 0; r < th.breachStats.length; r++) {
                let eachIt = th.breachStats[r];
                if (eachIt.breach.name) {
                  eachIt.name = eachIt.breach.name;
                }
              }
              console.debug(th.breachStats);
            }
          })
          .catch(function (error) {
            console.debug(error);
            return;
          });
      } else {
        console.debug("A search term must be entered.");
        return;
      }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 100;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    callback(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.addBreachStats();
        }
      });
    },
    addBreachStats() {
      let th = this;
      if (th.stopLoading == false) {
        console.debug("adding items...");
        th.currentPage++;
        let params = {};
        if (th.currentSortValue == null && th.searchTerm.length > 0) {
          statisticsBreachesGet(params, {
            results_per_page: 21,
            page: th.currentPage,
            q: th.searchTerm,
            sort_order: "desc",
            sort_by: "modified_at",
          })
            .then(function (result) {
              if (result.data.data.length == 0) {
                console.debug("All results loaded");
                th.showClipboard = true;
                th.clipboardMessage = "All results loaded";
                return;
              } else {
                store.commit(
                  "breachDashboard/addMoreBreachStats",
                  result.data.data
                );

                for (
                  let r = 0;
                  r < store.state.breachDashboard.breachStats.length;
                  r++
                ) {
                  let eachIt = store.state.breachDashboard.breachStats[r];
                  if (eachIt.breach.name) {
                    eachIt.name = eachIt.breach.name;
                  }
                }
                console.debug(th.breachStats);
              }
            })
            .catch(function (error) {
              console.debug(error);
              return;
            });
        } else if (th.currentSortValue == null && th.searchTerm.length == 0) {
          statisticsBreachesGet(params, {
            results_per_page: 21,
            page: th.currentPage,
            latest: true,
            sort_by: "modified_at",
            sort_order: "desc",
          })
            .then(function (result) {
              if (result.data.data.length == 0) {
                console.debug("All results loaded");
                th.showClipboard = true;
                th.clipboardMessage = "All results loaded";
                return;
              } else {
                store.commit(
                  "breachDashboard/addMoreBreachStats",
                  result.data.data
                );

                for (
                  let r = 0;
                  r < store.state.breachDashboard.breachStats.length;
                  r++
                ) {
                  let eachIt = store.state.breachDashboard.breachStats[r];
                  if (eachIt.breach.name) {
                    eachIt.name = eachIt.breach.name;
                  }
                }
                console.debug(th.breachStats);
              }
            })
            .catch(function (error) {
              console.debug(error);
              return;
            });
        }
        if (th.currentSortValue !== null) {
          console.warn(th.currentSortValue);
          statisticsBreachesGet(params, {
            results_per_page: 21,
            page: th.currentPage,
            sort_by: th.currentSortValue,
            sort_order: "desc",
          })
            .then(function (result) {
              console.debug(result);
              if (result.data.data.length == 0) {
                console.debug("All results loaded");
                th.showClipboard = true;
                th.clipboardMessage = "All results loaded";
                return;
              } else {
                for (let d = 0; d < result.data.data.length; d++) {
                  let eachData = result.data.data[d];
                  store.commit("breachDashboard/addMoreBreachStats", eachData);
                }
                for (
                  let r = 0;
                  r < store.state.breachDashboard.breachStats.length;
                  r++
                ) {
                  let eachIt = store.state.breachDashboard.breachStats[r];
                  if (eachIt.breach.name) {
                    eachIt.name = eachIt.breach.name;
                  }
                }
                setTimeout(() => {
                  if (th.currentSortValue == "organization") {
                    let cardFlexClass =
                      document.getElementsByClassName("class_0");
                    th.addMoreUpdateCards(cardFlexClass);
                  }
                  if (th.currentSortValue == "email") {
                    let cardFlexClass =
                      document.getElementsByClassName("class_1");
                    th.addMoreUpdateCards(cardFlexClass);
                  }
                  if (th.currentSortValue == "personal_information") {
                    let cardFlexClass =
                      document.getElementsByClassName("class_2");
                    th.addMoreUpdateCards(cardFlexClass);
                  }
                  if (th.currentSortValue == "ip") {
                    let cardFlexClass =
                      document.getElementsByClassName("class_3");
                    th.addMoreUpdateCards(cardFlexClass);
                  }
                  if (th.currentSortValue == "password") {
                    let cardFlexClass =
                      document.getElementsByClassName("class_4");
                    th.addMoreUpdateCards(cardFlexClass);
                  }
                  if (th.currentSortValue == "address") {
                    let cardFlexClass =
                      document.getElementsByClassName("class_5");
                    th.addMoreUpdateCards(cardFlexClass);
                  }
                }, 500);
              }
            })
            .catch(function (error) {
              console.debug(error);
            });
        }
      } else {
        console.debug("All items have been loaded");
        th.snackbar = true;
      }
    },
    wordClickHandler(name, value, vm) {
      //console.log("wordClickHandler", name, value, vm);
    },
    formatDate(date) {
      var fDate = new Date(date);
      return (
        (fDate.getMonth() > 8
          ? fDate.getMonth() + 1
          : "0" + (fDate.getMonth() + 1)) +
        "/" +
        (fDate.getDate() > 9 ? fDate.getDate() : "0" + fDate.getDate()) +
        "/" +
        fDate.getFullYear()
      );
    },
    kFormatter(n) {
      if (n < 1e3) return n;
      if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(0) + "K";
      if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(0) + "M";
      if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(0) + "B";
      if (n >= 1e12) return +(n / 1e12).toFixed(0) + "T";
    },
    kFormatterDecimal(n) {
      if (n < 1e3) return n;
      if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
      if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
      if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
      if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    createChart(chartId) {
      const ctx = document.getElementById(chartId);
      /**Customize the Rectangle.prototype draw method**/
      Chart.elements.Rectangle.prototype.draw = function () {
        var ctx = this._chart.ctx;
        var vm = this._view;
        var left, right, top, bottom, signX, signY, borderSkipped, radius;
        var borderWidth = vm.borderWidth;
        // If radius is less than 0 or is large enough to cause drawing errors a max
        //      radius is imposed. If cornerRadius is not defined set it to 0.
        var cornerRadius = this._chart.config.options.cornerRadius;
        var fullCornerRadius = this._chart.config.options.fullCornerRadius;
        var stackedRounded = this._chart.config.options.stackedRounded;
        var typeOfChart = this._chart.config.type;
        if (cornerRadius < 0) {
          cornerRadius = 0;
        }
        if (typeof cornerRadius == "undefined") {
          cornerRadius = 0;
        }
        if (typeof fullCornerRadius == "undefined") {
          fullCornerRadius = false;
        }
        if (typeof stackedRounded == "undefined") {
          stackedRounded = false;
        }
        if (!vm.horizontal) {
          // bar
          left = vm.x - vm.width / 2;
          right = vm.x + vm.width / 2;
          top = vm.y;
          bottom = vm.base;
          signX = 1;
          signY = bottom > top ? 1 : -1;
          borderSkipped = vm.borderSkipped || "bottom";
        } else {
          // horizontal bar
          left = vm.base;
          right = vm.x;
          top = vm.y - vm.height / 2;
          bottom = vm.y + vm.height / 2;
          signX = right > left ? 1 : -1;
          signY = 1;
          borderSkipped = vm.borderSkipped || "left";
        }
        // Canvas doesn't allow us to stroke inside the width so we can
        // adjust the sizes to fit if we're setting a stroke on the line
        if (borderWidth) {
          // borderWidth shold be less than bar width and bar height.
          var barSize = Math.min(
            Math.abs(left - right),
            Math.abs(top - bottom)
          );
          borderWidth = borderWidth > barSize ? barSize : borderWidth;
          var halfStroke = borderWidth / 2;
          // Adjust borderWidth when bar top position is near vm.base(zero).
          var borderLeft =
            left + (borderSkipped !== "left" ? halfStroke * signX : 0);
          var borderRight =
            right + (borderSkipped !== "right" ? -halfStroke * signX : 0);
          var borderTop =
            top + (borderSkipped !== "top" ? halfStroke * signY : 0);
          var borderBottom =
            bottom + (borderSkipped !== "bottom" ? -halfStroke * signY : 0);
          // not become a vertical line?
          if (borderLeft !== borderRight) {
            top = borderTop;
            bottom = borderBottom;
          }
          // not become a horizontal line?
          if (borderTop !== borderBottom) {
            left = borderLeft;
            right = borderRight;
          }
        }
        ctx.beginPath();
        ctx.fillStyle = vm.backgroundColor;
        ctx.strokeStyle = vm.borderColor;
        ctx.lineWidth = borderWidth;
        // Corner points, from bottom-left to bottom-right clockwise
        // | 1 2 |
        // | 0 3 |
        var corners = [
          [left, bottom],
          [left, top],
          [right, top],
          [right, bottom],
        ];
        // Find first (starting) corner with fallback to 'bottom'
        var borders = ["bottom", "left", "top", "right"];
        var startCorner = borders.indexOf(borderSkipped, 0);
        if (startCorner === -1) {
          startCorner = 0;
        }
        function cornerAt(index) {
          return corners[(startCorner + index) % 4];
        }
        // Draw rectangle from 'startCorner'
        var corner = cornerAt(0);
        ctx.moveTo(corner[0], corner[1]);
        var nextCornerId, nextCorner, width, height, x, y;
        for (var i = 1; i < 4; i++) {
          corner = cornerAt(i);
          nextCornerId = i + 1;
          if (nextCornerId == 4) {
            nextCornerId = 0;
          }
          nextCorner = cornerAt(nextCornerId);
          width = corners[2][0] - corners[1][0];
          height = corners[0][1] - corners[1][1];
          x = corners[1][0];
          y = corners[1][1];
          var radius = cornerRadius;
          // Fix radius being too large
          if (radius > Math.abs(height) / 2) {
            radius = Math.floor(Math.abs(height) / 2);
          }
          if (radius > Math.abs(width) / 2) {
            radius = Math.floor(Math.abs(width) / 2);
          }
          var x_tl, x_tr, y_tl, y_tr, x_bl, x_br, y_bl, y_br;
          if (height < 0) {
            // Negative values in a standard bar chart
            x_tl = x;
            x_tr = x + width;
            y_tl = y + height;
            y_tr = y + height;
            x_bl = x;
            x_br = x + width;
            y_bl = y;
            y_br = y;
            // Draw
            ctx.moveTo(x_bl + radius, y_bl);
            ctx.lineTo(x_br - radius, y_br);
            // bottom right
            ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius);
            ctx.lineTo(x_tr, y_tr + radius);
            // top right
            fullCornerRadius
              ? ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr)
              : ctx.lineTo(x_tr, y_tr, x_tr - radius, y_tr);
            ctx.lineTo(x_tl + radius, y_tl);
            // top left
            fullCornerRadius
              ? ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius)
              : ctx.lineTo(x_tl, y_tl, x_tl, y_tl + radius);
            ctx.lineTo(x_bl, y_bl - radius);
            //  bottom left
            ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);
          } else if (width < 0) {
            // Negative values in a horizontal bar chart
            x_tl = x + width;
            x_tr = x;
            y_tl = y;
            y_tr = y;
            x_bl = x + width;
            x_br = x;
            y_bl = y + height;
            y_br = y + height;
            // Draw
            ctx.moveTo(x_bl + radius, y_bl);
            ctx.lineTo(x_br - radius, y_br);
            //  Bottom right corner
            fullCornerRadius
              ? ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius)
              : ctx.lineTo(x_br, y_br, x_br, y_br - radius);
            ctx.lineTo(x_tr, y_tr + radius);
            // top right Corner
            fullCornerRadius
              ? ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr)
              : ctx.lineTo(x_tr, y_tr, x_tr - radius, y_tr);
            ctx.lineTo(x_tl + radius, y_tl);
            // top left corner
            ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius);
            ctx.lineTo(x_bl, y_bl - radius);
            //  bttom left corner
            ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);
          } else {
            var lastVisible = 0;
            for (
              var findLast = 0, findLastTo = this._chart.data.datasets.length;
              findLast < findLastTo;
              findLast++
            ) {
              if (!this._chart.getDatasetMeta(findLast).hidden) {
                lastVisible = findLast;
              }
            }
            var rounded = this._datasetIndex === lastVisible;
            if (rounded) {
              //Positive Value
              ctx.moveTo(x + radius, y);
              ctx.lineTo(x + width - radius, y);
              // top right
              ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
              ctx.lineTo(x + width, y + height - radius);
              // bottom right
              if (fullCornerRadius || typeOfChart == "horizontalBar")
                ctx.quadraticCurveTo(
                  x + width,
                  y + height,
                  x + width - radius,
                  y + height
                );
              else
                ctx.lineTo(
                  x + width,
                  y + height,
                  x + width - radius,
                  y + height
                );
              ctx.lineTo(x + radius, y + height);
              // bottom left
              if (fullCornerRadius)
                ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
              else ctx.lineTo(x, y + height, x, y + height - radius);
              ctx.lineTo(x, y + radius);
              // top left
              if (fullCornerRadius || typeOfChart == "bar")
                ctx.quadraticCurveTo(x, y, x + radius, y);
              else ctx.lineTo(x, y, x + radius, y);
            } else {
              ctx.moveTo(x, y);
              ctx.lineTo(x + width, y);
              ctx.lineTo(x + width, y + height);
              ctx.lineTo(x, y + height);
              ctx.lineTo(x, y);
            }
          }
        }
        ctx.fill();
        if (borderWidth) {
          ctx.stroke();
        }
      };
      this.barChart = new Chart(ctx, {
        type: "horizontalBar",
        data: {
          labels: this.barLabels,
          datasets: [
            {
              data: this.barData,
              fill: true,
              backgroundColor: [
                "rgba(232, 96, 81, 1)",
                "rgba(88, 171, 231, 1)",
                "rgba(102, 88, 231, 1)",
                "rgba(158, 88, 219, 1)",
                "rgba(219, 88, 201, 1)",
                "rgba(82, 210, 197, 1)",
              ],
              borderColor: [
                "rgba(232, 96, 81)",
                "rgba(88, 171, 231)",
                "rgba(102, 88, 231)",
                "rgba(158, 88, 219)",
                "rgba(219, 88, 201)",
                "rgba(82, 210, 197)",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          cornerRadius: 4,
          fullCornerRadius: true,
          stackedRounded: false,
          responsive: false,
          legend: {
            display: false,
          },
          layout: {
            padding: {
              left: 0,
              right: 20,
              top: 20,
              bottom: 20,
            },
          },
          tooltips: {
            titleFontSize: 20,
            bodyFontSize: 20,
            bodySpacing: 10,
            titleMarginBottom: 10,
            footerMarginTop: 10,
            mode: "label",
            callbacks: {
              label: function (tooltipItem, data) {
                return data["datasets"][0]["data"][tooltipItem["index"]] + "%";
              },
            },
          },
          scales: {
            xAxes: [
              {
                ticks: { beginAtZero: true, display: false },
                type: "linear",
                display: false,
                gridLines: { display: false },
                scaleLabel: {
                  display: true,
                  labelString: "Percentage",
                },
              },
            ],
            yAxes: [
              {
                type: "category",
                gridLines: { display: false },
                ticks: {
                  display: true,
                  drawOnChartArea: false,
                  color: "#112748",
                  lineWidth: 0,
                  fontSize: 15,
                  fontColor: "#fff",
                },
              },
            ],
          },
        },
      });
      this.barChart.update();
    },
  },
};
